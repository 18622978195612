<script setup>
import { computed } from 'vue'
import { useProfileInfoInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/profileInfoInventory.store'
import { storeToRefs } from 'pinia'
import dayjs from 'dayjs'

const profileInfoInventoryStore = useProfileInfoInventoryStore()
const { _profileInfoInventory } = storeToRefs(profileInfoInventoryStore)

// Establecer el valor de los atributos del registro
const fieldAttributeValues = computed(() => {
  return _profileInfoInventory.value.fieldsAttributes.map(fieldAttribute => {
    if (Array.isArray(fieldAttribute.value) && fieldAttribute.value.length > 0) {
      return fieldAttribute.value.join(', ')
    } else if (!Array.isArray(fieldAttribute.value) && fieldAttribute.value) {
      return fieldAttribute.dataType === 'date' ? dayjs(fieldAttribute.value).format('YYYY-MM-DD') : fieldAttribute.value
    } else {
      return 'Sin asignar'
    }
  })
})
</script>

<template>
  <div class="box intro-x p-4">
    <h3 class="text-base text-gray-600 font-bold mb-2">Detalles</h3>

    <!-- Atributos -->
    <div class="grid grid-cols-12 gap-2">
      <div
        class="col-span-12 flex"
        v-for="(fieldAttribute, index) in _profileInfoInventory.fieldsAttributes"
        :key="index"
      >
        <div class="flex flex-col sm:flex-row flex-grow p-my-lg-0 pr-3 justify-between p-2 rounded-lg bg-gray-50">
          <span class="text-gray-500 text-xs">{{ fieldAttribute.showName }}:</span>
          <span :class="(Array.isArray(fieldAttribute.value) && fieldAttribute.value.length > 0) || (!Array.isArray(fieldAttribute.value) && fieldAttribute.value) ? 'text-gray-500 text-sm font-semibold' : 'text-sm text-yellow-800 font-light italic opacity-50'">
            {{ fieldAttributeValues[index] }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
