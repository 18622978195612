import { defineStore } from 'pinia'

import DeviceService from '@/apps/dailyTech/inventario-ti/services/devices.service'
import InventoryService from '@/apps/dailyTech/inventario-ti/services/inventory.service'
import BrandService from '@/apps/dailyTech/inventario-ti/services/brands.service'
import ModelsService from '@/apps/dailyTech/inventario-ti/services/models.service'
import LocationsService from '@/apps/dailyTech/inventario-ti/services/locations.service'
import OwnersService from '@/apps/dailyTech/inventario-ti/services/owners.service'
import StatusService from '@/apps/dailyTech/inventario-ti/services/status.service'

const _DeviceService = new DeviceService()
const _InventoryService = new InventoryService()
const _BrandService = new BrandService()
const _ModelsService = new ModelsService()
const _LocationsService = new LocationsService()
const _OwnersService = new OwnersService()
const _StatusService = new StatusService()

export const useProfileInfoInventoryStore = defineStore({
  id: 'profileInfoInventoryStore',
  state: () => ({
    _profileInfoInventory: {},
    _listTypeDevices: [],
    _listBrands: [],
    _listModels: [],
    _listLocations: [],
    _listOwners: [],
    _listStatus: [],
    _formEditSubmitted: false,
    _isValidFormEdit: true,
    _messageErrorsEdit: [],
    _photoEdit: []
  }),
  getters: {
    _getInfoInventory: (state) => state._profileInfoInventory,
    _getListTypeDevices: (state) => state._listTypeDevices,
    _getListBrands: (state) => state._listBrands,
    _getListModels: (state) => state._listModels,
    _getListLocations: (state) => state._listLocations,
    _getListOwners: (state) => state._listOwners,
    _getListStatus: (state) => state._listStatus,
    _getFormEditSubmitted: (state) => state._formEditSubmitted,
    _getIsValidFormEdit: (state) => state._isValidFormEdit,
    _getMessageErrorsEdit: (state) => state._messageErrorsEdit,
    _getPhotoEdit: (state) => state._photoEdit
  },
  actions: {
    setListTypeDevices (payload) {
      this._listTypeDevices = payload
    },
    setListBrands (payload) {
      this._listBrands = payload
    },
    setListModels (payload) {
      this._listModels = payload
    },
    setListLocations (payload) {
      this._listLocations = payload
    },
    setListOwners (payload) {
      this._listOwners = payload
    },
    setListStatus (payload) {
      this._listStatus = payload
    },
    _setProfileInfoInventory (payload) {
      this._profileInfoInventory = payload
    },
    _setFormEditSubmitted (payload) {
      this._formEditSubmitted = payload
    },
    _setIsValidFormEdit (payload) {
      this._isValidFormEdit = payload
    },
    _setMessageErrorsEdit (payload) {
      this._messageErrorsEdit = payload
    },
    _setPhotoEdit (payload) {
      this._photoEdit = payload
    },
    _actionClearProfileInfoInventory () {
      this._profileInfoInventory = {}
      this._formEditSubmitted = false
      this._isValidFormEdit = true
      this._messageErrorsEdit = []
      this._photoEdit = []
    },
    async _actionProfileInfoInventory (payload) {
      try {
        const { id } = payload
        const response = await _InventoryService.findInventory(id)

        this._profileInfoInventory = response.data
      } catch (e) {
        console.error(e)
      }
    },
    async _actionListTypeDevices () {
      try {
        const { data } = await _DeviceService.getDevices()
        this.setListTypeDevices(data)
      } catch (error) {
        console.log('Error en _actionFilterTypeDevice', error)
      }
    },
    async _actionListBrands () {
      try {
        const { data } = await _BrandService.getBrands()
        this.setListBrands(data)
      } catch (error) {
        console.log('Error en _actionListBrands', error)
      }
    },
    async _actionListModels (payload) {
      try {
        const { data } = await _ModelsService.getModels({ idbrand: payload || null })
        this.setListModels(data)
      } catch (error) {
        console.log('Error en _actionListModels', error)
      }
    },
    async _actionListLocations () {
      try {
        const { data } = await _LocationsService.getLocations()
        this.setListLocations(data)
      } catch (error) {
        console.log('Error en _actionListLocations', error)
      }
    },
    async _actionListOwners () {
      try {
        const { data } = await _OwnersService.getOwners()
        this.setListOwners(data)
      } catch (error) {
        console.log('Error en _actionListOwners', error)
      }
    },
    async _actionListStatus () {
      try {
        const { data } = await _StatusService.getStatus()
        this.setListStatus(data)
      } catch (error) {
        console.log('Error en _actionListStatus', error)
      }
    }
  }
})
